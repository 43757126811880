import * as React from "react"

import Layout from "../../../components/layout"
import Seo from "../../../components/seo"

const CoorporateGovPage = () => (
  <Layout>
    <Seo title="Home" />
    <h1>Hi people</h1>
  </Layout>
)

export default CoorporateGovPage
